import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
// Config object to be passed to Msal on creation
 
let clientId = process.env.REACT_APP_CLIENT_ID;
let authority = process.env.REACT_APP_AUTHORITY;
let redirectUri = process.env.REACT_APP_REDIRECT_URI;
let postLogoutRedirectUri = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;
let refersh_token = process.env.REACT_APP_REFRESH_TOKEN;
 
if(process.env.NODE_ENV === "production" && window.location.href.split(".")[3] === "staging") {    
    clientId = "bc7e28c6-eaa0-448f-946b-52db8a54d45f";
    authority = "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/";
    redirectUri = "https://atci.lkmassetdirectory.staging.accenture.com";
    postLogoutRedirectUri = "https://atci.lkmassetdirectory.staging.accenture.com";
    refersh_token = "bc7e28c6-eaa0-448f-946b-52db8a54d45f";
}
 
export const msalConfig = {
  auth: {
      clientId: clientId,
      authority: authority,            
      redirectUri: redirectUri,
      postLogoutRedirectUri: postLogoutRedirectUri
  },
 
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {  
                return;
            }
            switch (level) {    
                case LogLevel.Error:    
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);  
                    return;
                case LogLevel.Verbose:  
                    console.debug(message);
                    return;
                case LogLevel.Warning:  
                    console.warn(message);  
                    return;
                default:
                    return;
            }
        }
    }
}
};
 
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["openid","profile","email","User.Read"]
};
 
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
 
 
export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
  headers: {
  Authorization: `Bearer ${accessToken}`,
  },
  });
         
  return response.json();
  };
  export const AUTH_REQUESTS = {
    LOGIN: {
    scopes: ['openid', 'profile'],
    },
    EMAIL: {
    scopes: [],
    },
    REFRESH_TOKEN: {
    scopes: [refersh_token],
    },
 };