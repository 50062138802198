import axios from "axios";

/**
 * CourseService class to handle course-related API calls.
 */
class CourseService {
  static BASE_URL = process.env.REACT_APP_BASE_URL;

  /**
   * Fetches all group course ids from the backend.
   * @returns {Promise<Array>} The list of all group course IDS.
   * @throws Will throw an error if the request fails.
   */
  static async getGroupCourseNames() {
    try {
      const response = await axios.get(
        `${CourseService.BASE_URL}/admin/api/master-courses/get-group-course-names`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Fetches all component course ids from the backend.
   * @returns {Promise<Array>} The list of all component course IDS.
   * @throws Will throw an error if the request fails.
   */
  static async getComponentCourseNames() {
    try {
      const response = await axios.get(
        `${CourseService.BASE_URL}/admin/api/master-courses/get-component-course-names`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
  
  /**
   * Fetches all component course ids from the backend.
   * @returns {Promise<Array>} The list of all component course IDS.
   * @throws Will throw an error if the request fails.
   */
  static async findByComponentCourseName(componentCourseName) {
    try {
      const response = await axios.get(
        `${CourseService.BASE_URL}/admin/api/master-courses/find-course-by-component-course-name/${componentCourseName}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }
  /**
   * Fetches all component course ids from the backend.
   * @returns {Promise<Array>} The list of all component course IDS.
   * @throws Will throw an error if the request fails.
   */
  static async findByGroupCourseName(groupCourseName) {
    try {
      const response = await axios.get(
        `${CourseService.BASE_URL}/admin/api/master-courses/find-course-by-group-course-name/${groupCourseName}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  /* */
  static async getCourse(courseId) {
    try {
      const response = await axios.get(`${CourseService.BASE_URL}/admin/api/master-courses/get-course/${courseId}`);
      return response.data;
    } catch (err) {
      console.error("Error fetching course details by courseId:", err);
      throw err;
    }
  }

  static async getGroupCourseID(groupCourseName) {
    try {
      const response = await axios.get(`${CourseService.BASE_URL}/admin/api/master-courses/get-group-course-id/${groupCourseName}`);
      return response.data;
    } catch (err) {
      console.error("Error fetching group course name by groupCourseId:", err);
      throw err;
    }
  }

  static async getAllCourses() {
    try {
      const response = await axios.get(`${CourseService.BASE_URL}/admin/api/master-courses/get-all-courses`);
      return response.data;
    } catch (err) {
      console.error("Error fetching all course details:", err);
      throw err;
    }
  }

  static async updateCourse(courseId, courseData) {
    try {
      const response = await axios.put(`${CourseService.BASE_URL}/admin/api/master-courses/update-course/${courseId}`,courseData);
      return response.data;
    } catch (err) {
      console.error("Error updating course:", err);
      throw err;
    }
  }

  static async deleteCourse(courseId) {
    try {
      const response = await axios.delete(`${CourseService.BASE_URL}/admin/api/master-courses/delete-course/${courseId}`);
      return response.data;
    } catch (err) {
      console.error("Error deleting course:", err); 
      throw err;
    }
  }

  static async addCourse(courseData) {
    try {
      const response = await axios.post(`${CourseService.BASE_URL}/admin/api/master-courses/add-course`,courseData);
      return response.data;
    } catch (err) {
      console.error("Error adding course:", err); 
      throw err;
    }
  }
}
export default CourseService;
