import axios from "axios";

/**
 * CourseService class to handle course-related API calls.
 */
class MonthlyOfferingService {
  static BASE_URL = process.env.REACT_APP_BASE_URL;

  /**
   * Saves the course data to the backend.
   * @param {Array} courses - The course data to save.
   * @returns {Promise<Object>} The response data from the backend.
   * @throws Will throw an error if the request fails.
   */
  static async saveMonthlyOffering(courses) {
    try {
      const response = await axios.post(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/create`,courses);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async calendarImport(formData) {
    try {
      const response = await axios.post(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/calendar/import`,formData);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async finalizeMonthlyOffering(selectedMonth) {
    const [year, month] = selectedMonth.split("-");
    try {
      const response = await axios.post(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/finalize/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async deleteMonthlyOffering(courses) {
    try {
      const response = await axios.delete(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/delete`, 
        {
          data: courses, // Include the request body in the `data` field
          headers: { 'Content-Type': 'application/json' }, // Optional, but ensures JSON format
        });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllUnnominatedCoursesBySMEWithinSelectedMonth(userId,selectedMonth){
    const [year, month] = selectedMonth.split("-");
    // console.log("getMonthlyOffering month: " + month);
    try {
      const response = await axios.get(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/get-all-unnominated-sme-offerings/${userId}/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllUnnominatedCoursesWithinSelectedMonth(selectedMonth){
    const [year, month] = selectedMonth.split("-");
    // console.log("getMonthlyOffering month: " + month);
    try {
      const response = await axios.get(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/get-all-unnominated-sme-offerings/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllCoursesWithinSelectedMonth(selectedMonth){
    const [year, month] = selectedMonth.split("-");
    try {
      const response = await axios.get(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/get-all-offerings/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllHolidays(selectedMonth){
    const [year, month] = selectedMonth.split("-");
    try {
      const response = await axios.get(
        `${MonthlyOfferingService.BASE_URL}/admin/api/monthly-offering/get-holidays/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}
export default MonthlyOfferingService;
