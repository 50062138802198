import axios from "axios";

/**
 * CourseService class to handle course-related API calls.
 */
class NominationService {
  static BASE_URL = process.env.REACT_APP_BASE_URL;

  /**
   * Saves the nomination data to the backend.
   * @param {Array} courses - The course data to save.
   * @returns {Promise<Object>} The response data from the backend.
   * @throws Will throw an error if the request fails.
   */
  static async saveNomination(nomination) {
    try {
      const response = await axios.post(
        `${NominationService.BASE_URL}/sme/api/nomination/save`,nomination);
      return response.data;
    } catch (err) {
      throw err;
    }
  }


  static async getSMENominationsByUserIdAndMonth(user,selectedMonth){
    const [year, month] = selectedMonth.split("-");
    // console.log("getMonthlyOffering month: " + month);
    try {
      const response = await axios.get(
        `${NominationService.BASE_URL}/sme/api/nomination/get-sme-nomination/${user.userId}/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async getAllSMENominationsByMonth(selectedMonth){
    const [year, month] = selectedMonth.split("-");
    // console.log("getMonthlyOffering month: " + month);
    try {
      const response = await axios.get(
        `${NominationService.BASE_URL}/sme/api/nomination/get-all-sme-nomination/${year}/${month}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async finalizeNomination(selectedMonth,selectedNominations) {
    const [year, month] = selectedMonth.split("-");
    try {
      const response = await axios.put(
        `${NominationService.BASE_URL}/sme/api/nomination/finalizeNomination/${year}/${month}`,selectedNominations);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async downloadFinalizedNominations(selectedMonth,selectedNominations) {
    const [year, month] = selectedMonth.split("-");
    try {
      const response = await axios.get(
        `${NominationService.BASE_URL}/sme/api/nomination/export-confirmed-nominations/${year}/${month}`,
        {
          responseType: "blob", // Ensure the response is treated as binary
        }
      );

      // Create a URL for the Excel file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Java_Nominations_${year}_${month}.xlsx`); // Specify the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the Excel file:", error);
    }
  }

  static async cancelNomination(nominations) {
    try {
      const response = await axios.put(
        `${NominationService.BASE_URL}/sme/api/nomination/cancel-nomination`, nominations);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
}

export default NominationService;
