import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import UserService from "./Service/UserService";
import AdminHome from "./Home/AdminHome";
import SMEHome from "./Home/SMEHome";
import InvalidUserHome from "./Home/InvalidUserHome";

const WelcomeName = () => {
    const { instance } = useMsal(); 
    const [user, setUser] = useState(null);
    const [name, setName] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name);
        } else {
            setName(null);
        }
    }, [activeAccount]); 

    useEffect(() => {
        if (name !== null) {
            setIsLoading(true); // Start loading
            fetchUser(activeAccount.username);
        }
    }, [name]);

    const fetchUser = async (username) => {
        try {
            const response = await UserService.getUserDetails(username);
            setUser(response);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; // Display a loading spinner or placeholder
    }

    if (user) {
        if (user.role === 'ADMIN') {
            return <AdminHome name={name} user={user} />;
        } else if (user.role === 'SME') {
            return <SMEHome name={name} user={user} />;
        }
    }

    return <InvalidUserHome />;
};

export default WelcomeName;
