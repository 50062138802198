import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
 
export const msalInstance = new PublicClientApplication(msalConfig);
 
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
 
// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();
 
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});
console.log(msalInstance);
ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
        <Router>
                <App pca={msalInstance} />
        </Router>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);