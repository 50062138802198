import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import NominationService from "../Service/NominationService";
import { useFreezeNomination } from "../Context/FreezeNominationContext";

function CheckSMENominationStatus() {
  const location = useLocation();
  const { user } = location.state || {}; // Extract user from location.state
  const [errorMsg, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedNomination, setSelectedNomination] = useState(null); // Single selected nomination
  const [showTable, setShowTable] = useState(false);
  const [nominations, setNominations] = useState([]);
  const [reason, setReason] = useState("");
  const [alternativeSME, setAlternativeSME] = useState("");
  const { isFrozen } = useFreezeNomination(); // Get isFrozen state from context

  const fetchNominationsByMonth = useCallback(async () => {
    try {
      const response = await NominationService.getSMENominationsByUserIdAndMonth(
        user,
        selectedMonth
      );
      if (response.length !== 0) {
        setNominations(response);
        setShowTable(true);
      } else {
        setNominations([]);
        setShowTable(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowTable(false);
    }
  }, [user, selectedMonth]);

  useEffect(() => {
    if (selectedMonth !== "") {
      fetchNominationsByMonth();
    }
  }, [selectedMonth, fetchNominationsByMonth]);

  const handleRadioChange = (nomination) => {
      setSelectedNomination(nomination);
      if (nomination.confirmationStatus === "NOMINATED") {
        setReason("");
        setAlternativeSME("");
      } else if (nomination.confirmationStatus === "CONFIRMED") {
        setReason("");
        setAlternativeSME("");
      }
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleAlternativeSMEChange = (event) => {
    setAlternativeSME(event.target.value);
  };

  const handleCancelNomination = async () => {
    if (!selectedNomination) {
      alert("Please select a nomination to cancel.");
      return;
    }
    if (!reason.trim()) {
      alert("Please provide a reason for cancellation, or enter 'NA'.");
      return;
    }
    if (selectedNomination.confirmationStatus === "CONFIRMED" && !alternativeSME.trim()) {
      alert("Please provide an alternative SME EmailID, or enter 'NA'.");
      return;
    }
    try {
      const response = await NominationService.cancelNomination(
        {
          nominationId: selectedNomination.nominationId,
          userId: user.userId,
          reason,
          alternativeSME,
        },
      );
      if (response === true) {
        alert("Nomination Cancellation request is sent successfully");
        fetchNominationsByMonth();
        setSelectedNomination(null);
        setReason("");
        setAlternativeSME("");
      }
    } catch (error) {
      setError("Failed to cancel nominations");
      console.error("Error cancelling nominations:", error);
      alert(errorMsg);
    }
  };

  const getWorkingDaysPrior = (startDate, workingDays) => {
    const date = new Date(startDate);
    let count = 0;

    while (count < workingDays) {
      date.setDate(date.getDate() - 1);
      const dayOfWeek = date.getDay();
      if (dayOfWeek !== 6 && dayOfWeek !== 0) {
        count++;
      }
    }
    return date;
  };

  const isRadioDisabled = (startDate, status) => {
    const currentDate = new Date();
    const fourWorkingDaysPrior = getWorkingDaysPrior(startDate, 4);
    return (
      status === "CANCELLED" ||
      status === "OPTED_OUT" ||
      status === "ASSIGNED_TO_OTHERS" ||
      currentDate >= fourWorkingDaysPrior ||isFrozen
    );
  };

  return (
    <div className="overflow-x-auto p-6 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-2xl font-semibold mb-4" align="center">
        Check Nomination Status Page
      </h2>
      <div className="w-1/3 mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="month">
            Select Month:
          </label>
          <input
            id="month"
            type="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            required
          />
        </div>
      </div>

      {showTable && nominations.length > 0 && (
        <div className="mt-6 w-full overflow-x-auto">
          <p className={`${
              isFrozen
                ? "bg-red-200 text-red-800 p-4 rounded-lg border border-red-300"
                : ""
            }`}
          >
            {isFrozen
              ? "Cancellation requests are frozen, changes are not allowed currently."
              : ""}
          </p>
          <br/>
          <h3 className="text-xl font-semibold mb-4">Nomination Details</h3>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 border border-gray-300">Group Course Name</th>
                <th className="px-4 py-2 border border-gray-300">Component Course Name</th>
                <th className="px-4 py-2 border border-gray-300">Mode Of Learning</th>
                <th className="px-4 py-2 border border-gray-300">Duration (Hours)</th>
                <th className="px-4 py-2 border border-gray-300">Duration (Days)</th>
                <th className="px-4 py-2 border border-gray-300">Start Date</th>
                <th className="px-4 py-2 border border-gray-300">End Date</th>
                <th className="px-4 py-2 border border-gray-300">Time</th>
                <th className="px-4 py-2 border border-gray-300">Program Name</th>
                <th className="px-4 py-2 border border-gray-300">Comment</th>
                <th className="px-4 py-2 border border-gray-300">Status</th>
                <th className="px-4 py-2 border border-gray-300">Cancel</th>
              </tr>
            </thead>
            <tbody>
              {nominations.map((nomination) => (
                <tr
                  key={nomination.offeringId}
                  className="border-b border-gray-200"
                >
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.groupCourseName}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.componentCourseName}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.modeOfLearning}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.durationInHours}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.durationInDays}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.startDate}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.endDate}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.time}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.programName}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.monthlyOfferingBean.comment}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    {nomination.confirmationStatus}
                  </td>
                  <td className="px-4 py-2 border border-gray-300">
                    <input
                      type="radio"
                      name="cancelNomination"
                      disabled={isRadioDisabled(
                        nomination.monthlyOfferingBean.startDate,
                        nomination.confirmationStatus
                      )}
                      checked={
                        selectedNomination &&
                        selectedNomination.nominationId === nomination.nominationId
                      }
                      onChange={() => handleRadioChange(nomination)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedNomination &&
            selectedNomination.confirmationStatus === "NOMINATED" && (
              <div>
                <label>
                  <b>Reason for cancellation: </b>
                </label>
                <br />
                <textarea
                  rows="2"
                  cols="180"
                  value={reason}
                  onChange={handleReasonChange}
                  placeholder="Enter reason here......"
                  className="w-1/3 px-4 py-2 border border-gray-300 rounded-md shadow-sm"
                ></textarea>
              </div>
            )}
          {selectedNomination &&
            selectedNomination.confirmationStatus === "CONFIRMED" && (
              <div>
                <label>
                  <b>Reason for cancellation: </b>
                </label>
                <br />
                <textarea
                  rows="2"
                  cols="180"
                  value={reason}
                  onChange={handleReasonChange}
                  placeholder="Enter reason here......"
                  className="w-1/3 px-4 py-2 border border-gray-300 rounded-md shadow-sm"
                ></textarea>
                <br />
                <label>
                  <b>Alternative SME EmailID: </b>
                </label>
                <input
                  type="email"
                  value={alternativeSME}
                  onChange={handleAlternativeSMEChange}
                  placeholder="Enter alternative SME's enterprise id"
                  className="w-1/3 px-4 py-2 border border-gray-300 rounded-md shadow-sm"
                ></input>
              </div>
            )}
          <div className="mt-4 flex justify-center">
            <button
              onClick={handleCancelNomination}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              disabled={isFrozen}
            >
              Request Nomination Cancellation
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckSMENominationStatus;
