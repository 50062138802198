import React from 'react'
import { NavLink } from 'react-router-dom';

export default function AdminHome({ name,user }) {
  return (
    <div>
          <div className="absolute top-0 right-8 m-5">
          <b>{name}</b>
        </div>
        <div className="absolute top-3 right-0 m-3">
        <img className="object-scale-down h-8 w-8" src="/useravatar.png" alt="User name icon"/>
        </div>
        <div className="absolute text-sm top-5 right-7 m-5">
        <i>&nbsp;Admin</i>
        </div>
        
        <h2 className="text-3xl font-semibold text-center text-black-600 my-10">LTT - Learning Offerings SME Nomination Tool</h2>
       
      <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto">
        <ul className="flex justify-center space-x-4 p-4">
          <li>
            <NavLink
              to="/admin/user-management"
              exact
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="bg-gray-700"
            >
              User Management
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/course-management"
              exact
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="bg-gray-700"
            >
              Course Management
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/learningOfferings"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="bg-gray-700"
            >
              Add Learning Offerings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/Nominationfinalize"
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="bg-gray-700"
            >
              Finalize Nomination
            </NavLink>
          </li>
          
          {/* Separation after "Finalize Nomination" */}
          <li className="border-l border-white-500 h-full mx-2">|</li>

          <li>
            <NavLink
              to={{
                pathname: "/admin/Nomination",
                state: { user } 
              }}
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="bg-gray-700"
            >
              SME - Nomination
            </NavLink>
          </li>
          <li>
            <NavLink
              to={{
                pathname: "/admin/Nominationstatus",
                state: { user } 
              }}
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="bg-gray-700"
            >
              SME - Check Nomination status
            </NavLink>
          </li>
          
        </ul>
      </div>
    </nav>
    </div>
  )
}
