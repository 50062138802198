import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MonthlyOfferingService from "../Service/MonthlyOfferingService";

function EventCalendar({ selectedMonth }) {
  moment.locale("in", {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  const [eventsfromdb, setEvents] = useState([]);
  const localizer = momentLocalizer(moment);
  const displayDate =
    selectedMonth !== ""
      ? new Date(selectedMonth.split("-")[0], selectedMonth.split("-")[1] - 1, 1)
      : new Date();

  useEffect(() => {
    if (selectedMonth !== "") {
      fetchEventsByMonth();
    }
  }, [selectedMonth]);

  // Fetch events from the database
  const fetchEventsByMonth = async () => {
    try {
      const response = await MonthlyOfferingService.getAllHolidays(selectedMonth);
      setEvents(response.length !== 0 ? response : []);
      console.log("events from db =", response);
    } catch (error) {
      console.error("Error fetching calendar holidays data:", error);
    }
  };

  // Transform events for the calendar
  const calenevents = eventsfromdb.map((event) => ({
    date: moment(event.eventDate).startOf("day").toDate(),
    type: event.floating ? "f" : "m",
    eventName: event.eventName,
  })).sort((a, b) => a.date - b.date);

  // Function to apply background color to date cells
  const dayPropGetter = (date) => {
    const matchedEvent = calenevents.find(
      (event) => moment(event.date).isSame(moment(date), "day")
    );
    if (matchedEvent) {
      const backgroundColor = matchedEvent.type === "m" ? "red" : "orange";
      return { style: { backgroundColor, color: "white", border: "none" } };
    }
    return {};
  };

  return (
    <div className="flex space-x-4">
      {/* Wrapper for Calendar and Event Names */}
      <div className="calendar-container">
        {/* Calendar */}
        <Calendar
          className="react-calendar"
          localizer={localizer}
          events={[]} // No events displayed on the calendar itself
          date={displayDate} // Controlled date prop
          onNavigate={() => {}} // Disable default navigation logic
          defaultView="month"
          views={{ day: true, week: true, month: true }}
          dayPropGetter={dayPropGetter} // Custom background color logic
        />
      </div>

      {/* Event Names Section */}
      <div className="event-names w-4/5 bg-gray-100 rounded-lg shadow-lg p-4">
        <ul className="text-base text-gray-700 text-left"> {/* Increased text size and aligned to left */}
          {calenevents.map((event, index) => (
            <li key={index} className="mb-2"> {/* Optional margin between list items */}
              {moment(event.date).format('D')} - {event.eventName} 
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EventCalendar;
