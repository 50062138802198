import React, { useEffect, useRef, useState } from "react";
import CourseService from "../Service/MasterCourseService";
import MonthlyOfferingService from "../Service/MonthlyOfferingService";
import EventCalendar from "../Common/EventCalendar";


/**
 * LearningOfferings component to manage and display learning offerings.
 * @returns {JSX.Element} The LearningOfferings component.
 */
function LearningOfferings() {
  const [error, setError] = useState(null);
  const [groupCourseNames, setGroupCourseNames] = useState([]);
  const [componentCourseNames, setcomponentCourseNames] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedComponentCourseName, setSelectedComponentCourseName] = useState("");
  const [selectedGroupCourseName, setSelectedGroupCourseName] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null); // Reference to the file input

  useEffect(() => {
    if (selectedMonth !== "") {
      fetchLearningOfferingsByMonth();
    }
  }, [selectedMonth]);

  const fetchLearningOfferingsByMonth = async () => {
    try {
      const response =
        await MonthlyOfferingService.getAllCoursesWithinSelectedMonth(selectedMonth);
      if (response.length !== 0) {
        setCourses(response);
        setShowTable(true);
      } else {
        setCourses([]);
        setShowTable(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowTable(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response1 = await CourseService.getGroupCourseNames();
      const response2 = await CourseService.getComponentCourseNames();
      setGroupCourseNames(response1);
      setcomponentCourseNames(response2);
    } catch (error) {
      setError("Failed to fetch courses");
      console.error("Error fetching courses:", error);
    }
  };

  const getComponentCourse = async () => {
    if (selectedMonth === "") {
      alert("Select a valid Month");
      return;
    }
    if (selectedComponentCourseName === "") {
      alert("Select a valid Component Course");
      return;
    }
    try {
      const response = await CourseService.findByComponentCourseName(selectedComponentCourseName);
      const courseWithDefaults = {
        ...response,
        startDate: response.startDate || "",
        endDate: response.endDate || "",
        time: response.time || "9:00 AM to 6:00 PM IST",
        comment: response.comment || "NA",
        programName: response.programName || "CALENDAR",
      };
      setCourses((prevCourses) => [...prevCourses, courseWithDefaults]);
      setShowTable(true);
    } catch (error) {
      setError("Failed to fetch courses");
      console.error("Error fetching courses:", error);
    }
  };

  const getGroupCourses = async () => {
    if (selectedMonth === "") {
      alert("Select a valid Month");
      return;
    }
    if (selectedGroupCourseName === "") {
      alert("Select a valid Group Course");
      return;
    }
    try {
      const response = await CourseService.findByGroupCourseName(selectedGroupCourseName);
      const coursesWithDefaults = response.map((course) => ({
        ...course,
        startDate: course.startDate || "",
        endDate: course.endDate || "",
        time: course.time || "9:00 AM to 6:00 PM IST",
        comment: course.comment || "NA",
        programName: course.programName || "CALENDAR",
      }));

      setCourses((prevCourses) => [...prevCourses, ...coursesWithDefaults]);
      setShowTable(true);
    } catch (error) {
      setError("Failed to fetch courses");
      console.error("Error fetching courses:", error);
    }
  };

  /**
   * Handler for updating startDate, endDate, time, and comment.
   */
  const handleInputChange = (index, field, value) => {
    //code to disable weekend selection
    const dateValue= new Date(value)
    if(dateValue.getDay()==0 || dateValue.getDay()==6 ){
      alert("Incorrect weekend dates selected! Please check!");
    }

    setCourses((prevCourses) =>
      prevCourses.map((course, i) => {
        if (i === index) {
          if (field === "startDate" || field === "durationInDays") {
            const startDate = field === "startDate" ? value : course.startDate;
            const trainingDays =
              field === "durationInDays" ? parseInt(value, 10) : parseInt(course.durationInDays, 10);
 
            if (startDate && trainingDays && !isNaN(trainingDays)) {
              const calculatedEndDate = calculateEndDateIncludingStart(startDate, trainingDays);
              return { ...course, [field]: value, endDate: calculatedEndDate };
            }
          }
          return { ...course, [field]: value };
        }
        return course;
      })
    );
  };

  /**
   * Handle checkbox state changes.
   */
  const handleCheckboxChange = (index, checked) => {
    setSelectedCourses((prevSelected) => ({
      ...prevSelected,
      [index]: checked,
    }));
  
    setSelectedRows((prevSelectedRows) => {
      const row = courses[index];
      if (checked) {
        // Add to selectedRows if checked and has a valid monthlyOfferingId
        return [...prevSelectedRows, { monthlyOfferingId: row.monthlyOfferingId || null }];
      } else {
        // Remove from selectedRows if unchecked
        return prevSelectedRows.filter(
          (selectedRow) => selectedRow.monthlyOfferingId !== row.monthlyOfferingId
        );
      }
    });
  };
  
  const calculateEndDateIncludingStart = (startDate, trainingDays) => {
    let currentDate = new Date(startDate);
    let daysAdded = 1; // Start date is already counted as the first day
   
    while (daysAdded < trainingDays) {
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      const dayOfWeek = currentDate.getDay();
   
      // Skip Saturdays (6) and Sundays (0)
      if (dayOfWeek !== 6 && dayOfWeek !== 0) {
        daysAdded++;
      }
    }
   
    // Format the date as "YYYY-MM-DD"
    return currentDate.toISOString().split("T")[0];
  };


  /**
   * Handle removing selected courses.
   */
  const handleRemoveCourses = async () => {
    // Check if no courses are selected
    if (Object.values(selectedCourses).every((selected) => !selected)) {
      alert("No courses selected for removal");
      return;
    }
    const filteredCourses = courses.filter((_, index) => !selectedCourses[index]);
    setCourses(filteredCourses);
    setSelectedCourses({});
    
    // Filter out rows with null or undefined monthlyOfferingId
    const validSelectedRows = selectedRows.filter(
      (row) => row.monthlyOfferingId !== null && row.monthlyOfferingId !== undefined
    );
    try {
      console.log(JSON.stringify(validSelectedRows, null, 2));
      const response = await MonthlyOfferingService.deleteMonthlyOffering(validSelectedRows);
      if (response === true) 
        alert("Monthly Offerings deleted successfully");
    } catch (error) {
      setError("Failed to delete courses");
      console.error("Error deleting courses:", error);
    }
    // Reset the selectedRows state
    setSelectedRows([]);
  };
  

  /**
   * Handle saving selected courses.
   */
  const handleSaveCourses = async () => {
    for (const course of courses) {
      if (!course.startDate || !course.endDate) {
        alert(
          "Date is not selected for one or more courses. Please select a date."
        );
        return;
      }
    }

    const overlappingCourses = checkForDateRangeOverlap();

    if (overlappingCourses.length > 0) {
      const confirmSave = window.confirm(
        "Dates are in the same range for some similar courses. Do you want to continue?"
      );
      if (!confirmSave) {
        // User clicked 'Cancel', so we just return without doing anything
        return;
      }
    }
    try {
      console.log(JSON.stringify(courses, null, 2));
      const response = await MonthlyOfferingService.saveMonthlyOffering(courses);
      if (response === true) alert("Monthly Offerings Saved successfully");
    } catch (error) {
      setError("Failed to save courses");
      console.error("Error saving courses:", error);
    }
  };

  /**
   * Handle Finalize/Open for Nominations.
   */
  const handleFinalizeCoursesAndOpenForNominations = async () => {
    const isConfirmed = window.confirm("Make sure any changes made to Learning Offerings are first Saved/Update.?");
    if (!isConfirmed) {
      return; // Exit if the user clicks "Cancel"
    }
    try {
      const response = await MonthlyOfferingService.finalizeMonthlyOffering(selectedMonth);
      if (response === true) {
        alert("Monthly Offerings Finalized and Opened for nominations.");
      }
    } catch (error) {
      setError("Failed to Finalize and Open for Nominations courses");
      console.error("Error saving courses:", error);
    }
  };
  
  /**
   * Function to check if any courses with the same componentCourseID
   * have overlapping date ranges.
   * @returns {Array} Array of overlapping courses if any, empty array if none.
   */
  const checkForDateRangeOverlap = () => {
    const overlappingCourses = [];

    // Group courses by componentCourseID
    const groupedCourses = courses.reduce((grouped, course) => {
      if (!grouped[course.componentCourseId]) {
        grouped[course.componentCourseId] = [];
      }
      grouped[course.componentCourseId].push(course);
      return grouped;
    }, {});

    // Check for overlapping dates within each componentCourseID group
    for (const courseGroup of Object.values(groupedCourses)) {
      for (let i = 0; i < courseGroup.length; i++) {
        const currentCourse = courseGroup[i];
        const currentStartDate = new Date(currentCourse.startDate);
        const currentEndDate = new Date(currentCourse.endDate);

        for (let j = i + 1; j < courseGroup.length; j++) {
          const comparisonCourse = courseGroup[j];
          const comparisonStartDate = new Date(comparisonCourse.startDate);
          const comparisonEndDate = new Date(comparisonCourse.endDate);

          // Check if date ranges overlap
          if (
            (currentStartDate <= comparisonEndDate && currentEndDate >= comparisonStartDate)
          ) {
            overlappingCourses.push(currentCourse, comparisonCourse);
          }
        }
      }
    }
    return overlappingCourses;
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setMessage(`File selected: ${file.name}`);
    }
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (!selectedFile) {
      setMessage("Please select a file before uploading.");
      return;
    }
    const formData = new FormData();
    formData.append("formData", selectedFile);
    try {
      const response = await MonthlyOfferingService.calendarImport(formData);
      if (response!=null) {
        alert("File uploaded successfully!");
        setSelectedFile(null);
        setMessage("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        const response = await MonthlyOfferingService.getAllCoursesWithinSelectedMonth(selectedMonth);
        setCourses(response);
        setShowTable(true);
      } 
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  //Kiran file upload code ends
  return (
    <div className="w-full overflow-x-auto p-1 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-2xl w-1/3 mx-auto font-semibold mb-4">
        Manage Monthly Learning Offerings Page
      </h2>
      <div className="flex justify-between items-start space-x-6 px-6 py-1">
      {/* Left and Middle Sections */}
      <div className="flex flex-col space-y-6 w-2/4">
        {/* Left Section */}
        <div className="w-full bg-gray-100 rounded-lg shadow-lg p-3">
          {/* Select Month */}
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="month">
              Select Month:
            </label>
            <input
              id="month"
              type="month"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>

          {/* Single Course ID */}
          <div className="mb-4 flex items-end">
            <div className="w-full">
              <label className="block text-gray-700" htmlFor="singleCourse">
                Select Single Course:
              </label>
              <select
                id="singleCourse"
                value={selectedComponentCourseName}
                onChange={(e) => setSelectedComponentCourseName(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <option value="">Select a single course</option>
                {componentCourseNames.map((componentCourseName) => (
                  <option key={componentCourseName} value={componentCourseName}>
                    {componentCourseName}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={getComponentCourse}
              className="ml-4 bg-purple-700 text-white px-4 py-2 rounded-md hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 h-full"
            >
              Add
            </button>
          </div>

          {/* Group Course ID */}
          <div className="mb-4 flex items-end">
            <div className="w-full">
              <label className="block text-gray-700" htmlFor="groupCourse">
                Select Group Course:
              </label>
              <select
                id="groupCourse"
                value={selectedGroupCourseName}
                onChange={(e) => setSelectedGroupCourseName(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                <option value="">Select a group course</option>
                {groupCourseNames.map((groupCourseName) => (
                  <option key={groupCourseName} value={groupCourseName}>
                    {groupCourseName}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={getGroupCourses}
              className="ml-4 bg-purple-700 text-white px-4 py-2 rounded-md hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 h-full"
            >
              Add
            </button>
          </div>
        </div>

        {/* Middle Section */}
        <div className="w-full bg-gray-100 rounded-lg shadow-lg p-1">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            disabled={selectedMonth === ""}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
          {message && (
            <p className="mt-4 text-blue-600 text-sm font-medium">{message}</p>
          )}
          <button
            onClick={handleFileUpload}
            className={`mt-4 w-full px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 ${
              selectedMonth === "" ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={selectedMonth === ""}
          >
            Upload File
          </button>
        </div>
      </div>

      {/* Right Section */}
      <div className="w-2/4 text-center bg-gray-100 rounded-lg shadow-lg p-1"> 
          <h3 className="text-lg font-bold text-gray-700 mb-4">Events Calendar and Events</h3>
          <EventCalendar selectedMonth={selectedMonth} />
      </div>
    </div>

      {/* Table displaying course details */}
      {showTable && (
        <div className="mt-6 w-full overflow-x-auto">
          <h3 className="text-xl font-semibold mb-4">Course Details</h3>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-1 py-1 border border-gray-300">Remove</th>
                <th className="px-1 py-1 border border-gray-300">Group Course ID</th>
                <th className="px-1 py-1 border border-gray-300">Course Name</th>
                <th className="px-1 py-1 border border-gray-300">Component Course ID</th>
                <th className="px-1 py-1 border border-gray-300">Course Name</th>
                <th className="px-1 py-1 border border-gray-300">Mode of Learning</th>
                <th className="px-1 py-1 border border-gray-300">Duration (Hours)</th>
                <th className="px-1 py-1 border border-gray-300">Duration (Days)</th>
                <th className="px-1 py-1 border border-gray-300">Start Date</th>
                <th className="px-1 py-1 border border-gray-300">End Date</th>
                <th className="px-1 py-1 border border-gray-300">Time</th>
                <th className="px-1 py-1 border border-gray-300">Program Name</th>
                <th className="px-1 py-1 border border-gray-300">Comment</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="px-1 py-1 border border-gray-300">
                    <input
                      type="checkbox"
                      checked={!!selectedCourses[index]}
                      onChange={(e) =>
                        handleCheckboxChange(index, e.target.checked)
                      }
                    />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    {course.groupCourseId}
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    {course.groupCourseName}
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    {course.componentCourseId}
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    {course.componentCourseName}
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    {course.modeOfLearning}
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    <input
                      type="text"
                      value={course.durationInHours}
                      onChange={(e) =>
                        handleInputChange(index, "durationInHours", e.target.value)
                      }
                      className="w-full px-1 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    <input
                      type="text"
                      value={course.durationInDays}
                      onChange={(e) =>
                        handleInputChange(index, "durationInDays", e.target.value)
                      }
                      className="w-full px-1 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    <input
                      type="date"
                      value={course.startDate || `${selectedMonth}-01`}
                      onChange={(e) =>
                        handleInputChange(index, "startDate", e.target.value)
                      }
                      className="w-full px-1 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    <input
                      type="date"
                      value={course.endDate || `${selectedMonth}-01`}
                      onChange={(e) =>
                        handleInputChange(index, "endDate", e.target.value)
                      }
                      className="w-full px-1 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                      <textarea
                        value={course.time || "9:00 AM to 6:00 PM IST"}
                        onChange={(e) =>
                          handleInputChange(index, "time", e.target.value)
                        }
                        className="w-full h-16 px-1 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                        style={{ minHeight: "50px", minWidth: "200px" }}
                      />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                    <input
                      type="text"
                      value={course.programName}
                      onChange={(e) =>
                        handleInputChange(index, "programName", e.target.value)
                      }
                      className="w-full px-1 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500" style={{ minWidth: "200px" }}
                    />
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                      <textarea
                        value={course.comment || "NA"}
                        onChange={(e) =>
                          handleInputChange(index, "comment", e.target.value)
                        }
                        className="w-full h-16 px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                        style={{ minHeight: "50px", minWidth: "200px" }}
                      />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-center">
            <button onClick={handleSaveCourses} className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2">
              Save/Update Course
            </button>
            <button onClick={handleRemoveCourses} className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 mr-2">
              Remove Course
            </button>
            <button onClick={handleFinalizeCoursesAndOpenForNominations} className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2">
              Finalize/Open for Nominations
            </button>
          </div>
      </div>
      )}
    </div>
  );
}

export default LearningOfferings;