import React, { useEffect, useState } from "react";
import NominationService from "../Service/NominationService";
import { useFreezeNomination } from "../Context/FreezeNominationContext";
import MonthlyOfferingService from "../Service/MonthlyOfferingService";

// function NominationFinalization_org() {
  function NominationFinalization() {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showCancelledModal, setShowCancelledModal] = useState(false); // New state for modal visibility
  const [showNoNominationsModal, setShowNoNominationsModal] = useState(false); // New state for modal visibility
  const [learningOfferings, setLearningOfferings] = useState([]);
  const [learningOfferingsWithNoNominations, setLearningOfferingsWithNoNominations] = useState([]);
  const [selectedNominations, setSelectedNominations] = useState([]); // New state for selected nominations
  const { isFrozen, toggleFreezeNomination } = useFreezeNomination(); // Get state and function from context

  useEffect(() => {
    if (selectedMonth !== "") {
      fetchLearningOfferingsByMonth();
    }
  }, [selectedMonth]);

  const fetchLearningOfferingsByMonth = async () => {
    try {
      const response = await NominationService.getAllSMENominationsByMonth(selectedMonth);
      if (response.length !== 0) {
        setLearningOfferings(response);
        setShowTable(true);
      } else {
        setLearningOfferings([]);
        setShowTable(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowTable(false);
    }
  };

// Group courses by componentCourseId, startDate, and endDate
const groupCoursesByComponentId = (courses) => {
  const groupedCourses = {};
  courses.forEach((course) => {
    const { componentCourseId, startDate, endDate } = course.monthlyOfferingBean;
    const key = `${componentCourseId}-${startDate}-${endDate}`;
    if (!groupedCourses[key]) {
      groupedCourses[key] = {
        ...course.monthlyOfferingBean, // Retain the course details
        nominatedUsers: [],
      };
    }

    // Push the user and userId into the nominatedUsers array
    groupedCourses[key].nominatedUsers.push({
      name: course.userBean.name,
      userId: course.userBean.userId,
      confirmationStatus: course.confirmationStatus,
    });
    
    // Update collective status based on user's confirmation statuses
    const statuses = groupedCourses[key].nominatedUsers.map((user) => user.confirmationStatus);
    if (statuses.includes("CONFIRMED")) {
      groupedCourses[key].collectiveStatus = "CONFIRMED";
    } else if (statuses.includes("CANCELLED")) {
        groupedCourses[key].collectiveStatus = "CANCELLED";
    } else {
      groupedCourses[key].collectiveStatus = "NOT YET CONFIRMED";
    }
  });
  return Object.values(groupedCourses);
};

const getCancelledOfferings = () => {
  return learningOfferings.filter((course) => course.confirmationStatus === "CANCELLED" || course.confirmationStatus === "OPTED_OUT");
};

  // Handle selection change for dropdown
const handleSelectionChange = (monthlyOfferingId, userId) => {
  setSelectedNominations((prevNominations) => {
    // Check if the selection for the monthlyOfferingId already exists
    const existingNomination = prevNominations.find(
      (nomination) => nomination.monthlyOfferingId === monthlyOfferingId
    );

    if (existingNomination) {
      // If it exists, update the UserID
      return prevNominations.map((nomination) =>
        nomination.monthlyOfferingId === monthlyOfferingId
          ? { ...nomination, userId }
          : nomination
      );
    } else {
      return [...prevNominations, { monthlyOfferingId, userId }];
    }
  });
};

  // Handle Finalize Nomination button click
  const handleFinalizeNominations = async () => {
    try {
      const response = await NominationService.finalizeNomination(selectedMonth,selectedNominations);
      alert(response);
    } catch (error) {
      console.error("Error finalizing nomination:", error);
      setShowTable(false);
    }
  };

  // Handle Finalize Nomination button click
  const handleOfferingsWithNoNominations = async () => {
    try {
      const response = await MonthlyOfferingService.getAllUnnominatedCoursesWithinSelectedMonth(selectedMonth);
      if (response.length !== 0) {
        setLearningOfferingsWithNoNominations(response);
        setShowNoNominationsModal(true);
      } else {
        setLearningOfferingsWithNoNominations([]);
        setShowNoNominationsModal(false);
      }
    } catch (error) {
      console.error("Error handling Offerings With No Nominations :", error);
      setShowTable(false);
    }
  };

  const downloadXLReport = async () => {
      const response = await NominationService.downloadFinalizedNominations(selectedMonth);
  };

  return (
    <div className="overflow-x-auto p-3 bg-white rounded-lg shadow-md mt-4">
      <h2 className="text-2xl font-semibold mb-4" align="center">
        Finalize Nomination Page
      </h2>
      <div className="w-1/3 mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="month">
            Select Month:
          </label>
          <input
            id="month"
            type="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            required
          />
        </div>
      </div>
      {showTable && learningOfferings.length > 0 && (
        <div className="mt-6 w-full overflow-x-auto">
          {/* Freeze Nomination Toggle */}
          <div className="flex justify-left mb-4">
          <label className="flex items-center cursor-pointer">
            {/* Hidden checkbox */}
            <input
              type="checkbox"
              checked={isFrozen}
              onChange={toggleFreezeNomination} 
              className="hidden"
            />
            {/* Custom toggle slider */}
            <div
              className={`relative w-12 h-6 rounded-full ${
                isFrozen ? "bg-red-500" : "bg-green-500"
              }`}
            >
              {/* Inner circle */}
              <div
                className={`absolute top-0 left-0 w-6 h-6 bg-white rounded-full shadow-md transition-all duration-200 ${
                  isFrozen ? "transform translate-x-6" : ""
                }`}
              ></div>
            </div>
            <span className="ml-2 text-black">
              {isFrozen ? "Toggle to Unfreeze for Nominations" : "Toogle to Freeze Nominations"}
            </span>
          </label>
          </div>
          <h3 className="text-xl font-semibold mb-4">Course Details</h3>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-1 py-1 border border-gray-300">Group Course ID</th>
                <th className="px-1 py-1 border border-gray-300">Group Course Name</th>
                <th className="px-1 py-1 border border-gray-300">Component Course ID</th>
                <th className="px-1 py-1 border border-gray-300">Component Course Name</th>
                <th className="px-1 py-1 border border-gray-300">Duration (Hours)</th>
                <th className="px-1 py-1 border border-gray-300">Duration (Days)</th>
                <th className="px-1 py-1 border border-gray-300">Start Date</th>
                <th className="px-1 py-1 border border-gray-300">End Date</th>
                <th className="px-1 py-1 border border-gray-300">Time</th>
                <th className="px-1 py-1 border border-gray-300">Comment</th>
                <th className="px-1 py-1 border border-gray-300">Nominated Users</th>
                <th className="px-1 py-1 border border-gray-300">Confirmation Status</th>
              </tr>
            </thead>
            <tbody>
              {groupCoursesByComponentId(learningOfferings).map((groupedCourse) => (
                <tr key={groupedCourse.componentCourseId} className="border-b border-gray-200">
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.groupCourseId}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.groupCourseName}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.componentCourseId}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.componentCourseName}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.durationInHours}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.durationInDays}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.startDate}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.endDate}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.time}</td>
                  <td className="px-1 py-1 border border-gray-300">{groupedCourse.comment}</td>

                  <td className="px-1 py-1 border border-gray-300">
                    <select
                      className="w-full px-4 py-2 border rounded-md"
                      style={{ minWidth: "150px" }}
                      onChange={(e) =>
                        handleSelectionChange(groupedCourse.monthlyOfferingId, e.target.value)
                      }
                    >
                      <option value="">--select--</option>
                      {groupedCourse.nominatedUsers.filter((user) => user.confirmationStatus !== 'CANCELLED' && user.confirmationStatus !== 'OPTED_OUT') .map((user) => (
                        <option key={user.userId} value={user.userId} selected={user.confirmationStatus==='CONFIRMED'}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="px-1 py-1 border border-gray-300">
                  {groupedCourse.collectiveStatus}
                </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4">
            <button
              className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
              onClick={handleFinalizeNominations}
            >
              Finalize Nominations
            </button>

            <button
              onClick={() => setShowCancelledModal(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
            >
              Show Cancelled Nominations
            </button>

            <button
              onClick={handleOfferingsWithNoNominations}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
            >
              Show Offerings with No Nominations
            </button>

            <button
              onClick={downloadXLReport}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
            >
              Download XL Report
            </button>
          </div>
        </div>
      )}
      {showCancelledModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-1 rounded-lg shadow-lg max-w-6xl w-full">
            <h3 className="text-xl font-semibold mb-4">Cancelled Offerings</h3>
            {/* Wrapper for the table with scroll */}
            <div className="max-h-96 overflow-y-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="px-1 py-1 border border-gray-300">Group Course Name</th>
                    <th className="px-1 py-1 border border-gray-300">Component Course Name</th>
                    <th className="px-1 py-1 border border-gray-300">Start Date</th>
                    <th className="px-1 py-1 border border-gray-300">End Date</th>
                    <th className="px-1 py-1 border border-gray-300">Time</th>
                    <th className="px-1 py-1 border border-gray-300">User Name</th>
                    <th className="px-1 py-1 border border-gray-300">Status</th>
                    <th className="px-1 py-1 border border-gray-300">Reason</th>
                    <th className="px-1 py-1 border border-gray-300">Alternative SME</th>
                  </tr>
                </thead>
                <tbody>
                  {getCancelledOfferings().map((offering) => (
                    <tr key={offering.nominationId} className="border-b border-gray-200">
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.monthlyOfferingBean.groupCourseName}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.monthlyOfferingBean.componentCourseName}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.monthlyOfferingBean.startDate}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.monthlyOfferingBean.endDate}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.monthlyOfferingBean.time}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.userBean.name}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">{offering.confirmationStatus}</td>
                      <td className="px-1 py-1 border border-gray-300">{offering.reason}</td>
                      <td className="px-1 py-1 border border-gray-300">{offering.alternativeSME}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={() => setShowCancelledModal(false)}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

{showNoNominationsModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-1 rounded-lg shadow-lg max-w-6xl w-full">
            <h3 className="text-xl font-semibold mb-4">Offerings with No Nominations</h3>
            {/* Wrapper for the table with scroll */}
            <div className="max-h-96 overflow-y-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="px-1 py-1 border border-gray-300">Group Course Name</th>
                    <th className="px-1 py-1 border border-gray-300">Component Course Name</th>
                    <th className="px-1 py-1 border border-gray-300">Start Date</th>
                    <th className="px-1 py-1 border border-gray-300">End Date</th>
                    <th className="px-1 py-1 border border-gray-300">Time</th>
                  </tr>
                </thead>
                <tbody>
                  {learningOfferingsWithNoNominations.map((offering) => (
                    <tr key={offering.monthlyOfferingId} className="border-b border-gray-200">
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.groupCourseName}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.componentCourseName}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.startDate}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.endDate}
                      </td>
                      <td className="px-1 py-1 border border-gray-300">
                        {offering.time}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={() => setShowNoNominationsModal(false)}
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedMonth && learningOfferings.length === 0 && (
        <p className="text-center text-gray-700 mt-4">No Data available for the selected month.</p>
      )}
    </div>
  );
}

// export default NominationFinalization_org;
export default NominationFinalization