import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import UserService from "../Service/UserService";

/**
 * RegistrationPage component for user registration.
 * @returns {JSX.Element} The RegistrationPage component.
 */
function AddUser() {
  const navigate = useHistory();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
  });

  /**
   * Handles input changes and updates the form data state.
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /**
   * Handles the form submission for user registration.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation for Accenture domain
    if (!formData.email.endsWith("@accenture.com")) {
      alert("Email must be a valid accenture email ending with @accenture.com");
      return;
    }

    try {
      await UserService.register(formData);
      setFormData({
        name: "",
        email: "",
        role: "",
      });
      alert("User registered successfully");
      navigate.push("/admin/user-management");
    } catch (error) {
      console.error("Error registering user:", error);
      alert("An error occurred while registering user");
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800">Add User</h2><br/>
      <form onSubmit={handleSubmit} className="max-w-lg p-6 bg-white rounded-lg shadow-md">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">
            Name:
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="name"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="email"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="role" className="block text-gray-700 text-sm font-semibold mb-2">
            Role:
          </label>
          <select
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="role"
          >
            <option value="">Select Role</option>
            <option value="ADMIN">ADMIN</option>
            <option value="SME">SME</option>
          </select>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Add User
        </button>
      </form>
    </div>
  );
}

export default AddUser;
