import React from 'react'

export default function InvalidUserHome() {
  return (
    <div>
      <h2 className="text-3xl font-semibold text-left text-red-600 my-10">
        Sorry! but you are not authorized to access this application
      </h2>
    </div>
  )
}
