import React from "react";

/**
 * Footer component to display the footer section of the application.
 * @returns {JSX.Element} The Footer component.
 */
const Footer = () => {
  return (
    <div className="w-full bg-purple-700 text-white py-2 text-center">
      <footer>
        <span>
          &copy; {new Date().getFullYear()} Accenture. All rights reserved.
          Accenture Confidential. For internal use only.
        </span>
      </footer>
    </div>
  );
};

export default Footer;
